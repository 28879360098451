module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-material-ui-virtual-f24c8ff1f2/0/cache/gatsby-plugin-material-ui-npm-2.1.10-b8f9cb8bdc-32a12c93b5.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-mdx-virtual-9ed3893121/0/cache/gatsby-plugin-mdx-npm-1.2.52-53dfca981b-ee1ebb83c8.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-9210b3c9a0/0/cache/gatsby-plugin-manifest-npm-2.4.37-294581bed4-e269f9117f.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#002B36","theme_color":"#268BD2","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
